<template>
    <PublicLayout>
        <v-row class="text-center d-flex flex-column py-12">
            <h4 class="text-md-h4 font-weight-black p-0 mb-3">
                Submit your NFT collection
            </h4>
        </v-row>
        <v-row v-if="nftoken">
            <v-col md="6" sm="12">
                <v-card
                    color="primary"
                    elevation="1"
                    class="text-body-2 pa-2"
                    rounded="xl"
                >
                    <v-card-title
                        ><h3 class="mx-auto">Free submission</h3></v-card-title
                    >
                    <v-card-subtitle class="text-center">
                        Get listed for free on NFTisMagic
                    </v-card-subtitle>
                    <v-card-text>
                        <NftokenSubmitForm />
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col md="6" sm="12">
                <PaidSubmission />
            </v-col>
        </v-row>
    </PublicLayout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "SubmitNftoken",
    components: {
        PublicLayout: () => import("@/layouts/public/Index.vue"),
        NftokenSubmitForm: () => import("@/components/NftokenSubmitForm.vue"),
        PaidSubmission: () => import("@/components/PaidSubmission.vue"),
    },
    computed: {
        ...mapGetters({
            nftoken: "nftoken/item",
        }),
    },
    methods: {
        ...mapActions({
            setDefaultToken: "nftoken/setDefaultItem",
        }),
    },
    created() {
        this.setDefaultToken();
    },
};
</script>

<style lang="scss" scoped>
</style>
